
import rest  from '@/api/index';
import Vue from 'vue';
import { mapState } from 'vuex';
import { ComposedState } from '@/types';
import { IRecipient } from '@/store/modules/log/types';
import NotificationsNav from './NotificationsNav.vue';
import RecipientsList from './RecipientsList.vue';

export default Vue.extend({
    name: 'Notifications',

    components: {
        RecipientsList,
        NotificationsNav,
    },

    data() {
        return {
            isSelectedAll: false,
            newEmails: '',
            recipients: [] as IRecipient[],
            text : '',
            visibleRecipientsLimit: 0,
            isShowMoreModalOpen: false,
            isShowNotification: false,
            notificationTextList: {
                success: 'Notifications have been sent successfully.',
                error: 'Notifications have NOT been sent. Please try sending again later.',
                noUniqueEmails: 'There are no new unique emails to add.',
                errorFetching: 'Error fetching email status',
                dontHaveRights: 'You dont have enough rights',
            },
            notificationText: '',
            sendNotificationSuccess: false,
            textLimit: 1000,
            exceededLimit: 0,
        };
    },
    computed: {
        ...mapState<ComposedState>({
            admin: (state: ComposedState) => state.admin.admin,
        }),

        cantSendNotification(): boolean {
            return this.recipients.length === 0 && !this.isSelectedAll 
                || !this.hasActiveEmail && !this.isSelectedAll 
                || this.text === '';
        },

        limitExceededMessage(): string {
            return `Please shorten your notice to ${this.exceededLimit} characters. 
                Current ${this.textLimit} character limit.`;
        },

        hasActiveEmail(): boolean {
            return this.recipients.some((rec) => 
                !!rec.is_recognized
                && !!rec.is_active
                && !!rec.is_valid,
            );
        },
    },

    created() {
        this.updateVisibleRecipientsLimit();
        window.addEventListener('resize', this.updateVisibleRecipientsLimit);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateVisibleRecipientsLimit);
    },

    methods: {
        addEmails(): void {
            const newEmails = this.newEmails
                .split(',')
                .map((email) => email.trim())
                .filter((email, index, self) =>
                    email && self.indexOf(email) === index,
                );

            const emailsToAdd = newEmails.filter(
                (email) => !this.recipients.some((recipient) => recipient.email === email),
            );

            if (emailsToAdd.length) {
                this.checkEmailsStatus(emailsToAdd);
            } else {
                this.showNotification(false, this.notificationTextList.noUniqueEmails);
            }

            // @ts-ignore
            this.newEmails = '';
        },

        async checkEmailsStatus(emails) {
            try {
                const resp = await rest.getEmailStatus(emails);

                // @ts-ignore
                this.recipients.push(...resp.data);
            } catch (error) {
                this.showNotification(false, this.notificationTextList.errorFetching);
            }
        },

        deleteEmail(index: number): void {
            this.recipients.splice(index, 1);
        },

        selectAllRecipients(): void {
            this.recipients = [];
        },

        async sendNotification(): Promise<void> {
            // @ts-ignore
            if (this.admin && !this.admin.isSuperuser) {
                this.showNotification(false, this.notificationTextList.dontHaveRights);
                return;
            }
            if (this.text.length > this.textLimit) {
                this.exceededLimit = this.text.length - this.textLimit;
                this.showNotification(false, this.limitExceededMessage);
                return;
            }
            const resp = await rest.sendNotification({ 
                recipients: this.recipients, 
                text: this.text,
            });

            if (resp && resp.status === 200) {
                this.resetForm();
                this.showNotification(true, this.notificationTextList.success);
            } else {
                this.showNotification(false, this.notificationTextList.error);
            }
        },

        showMore(): void {
            this.isShowMoreModalOpen = true;
        },

        resetForm(): void {
            this.recipients = [];
            this.text = '';
            this.isSelectedAll = false;
        },

        showNotification(isSuccess: boolean, text: string): void {
            this.sendNotificationSuccess = isSuccess;
            this.notificationText = text;
            this.isShowNotification = true;
        },

        updateVisibleRecipientsLimit(): void {
            const width = window.innerWidth;

            if (width > 1350) {
                this.visibleRecipientsLimit = 6;
            } else if (width > 750) {
                this.visibleRecipientsLimit = 4;
            } else {
                this.visibleRecipientsLimit = 2;
            }
        },
    },
});
